@layer components {
  .tab-component {
    @apply px-6 grid max-w-screen-sm mx-auto;

    .tabcontent ul {
      li {
        @apply my-7 pl-6;

        &::before {
          content: "\2022";
          display: block;
          float: left;
          position: relative;
          left: -13px;
        }
      }
    }

    & > ul {
      .tab-btn {
        @apply mb-6;

        .tab-link {
          @apply no-underline border-t text-orange pt-2.5 w-fit hover:border-t-[3px];

          &.active {
            @apply font-bold border-t-[3px];
          }
        }
      }
    }

    .tab-1 {
      div {
        @apply mb-5;

        h3 {
          @apply font-bold mb-2.5;
        }
      }
    }

    .tab-4 {
      details {
        p {
          @apply mt-2;
        }
      }
    }
  }

  .form-component {
    @apply px-6 grid max-w-screen-sm mx-auto;

    .tabcontent ul {
      li {
        @apply my-7 pl-6;

        &::before {
          content: "\2022";
          display: block;
          float: left;
          position: relative;
          left: -13px;
        }
      }
    }

    & > ul {
      .tab-btn {
        @apply mb-6;

        .tab-link {
          @apply no-underline border-t text-orange pt-2.5 w-fit hover:border-t-[3px];

          &.active {
            @apply font-bold border-t-[3px];
          }
        }
      }
    }

    .tab-1 {
      div {
        @apply mb-5;

        h3 {
          @apply font-bold mb-2.5;
        }
      }
    }

    .tab-4 {
      details {
        p {
          @apply mt-2;
        }
      }
    }
  }
}
