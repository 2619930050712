@import "vendors/tailwind";
@import "vendors/swiper-bundle.min";

@import "base/_base";
@import "base/_buttons";
@import "base/_fonts";
@import "base/_links";
@import "base/_lists";
@import 'base/_form';
@import "base/_typography";

@import 'components/_accordion';
@import 'components/_slider';
@import 'components/_modals';
@import 'components/_locatie';
@import 'components/_tabs';
@import 'components/_huidtherapeut';
@import 'components/_iframe';

@import 'components/includes/_ratings';
@import 'components/includes/behandeling.css';
@import 'components/includes/cards.css';
@import 'components/includes/embed.css';

@import 'components/shop/_products.css';
@import 'components/shop/_filters.css';
@import 'components/shop/_mollieForm.css';

@import 'layout/_footer';
@import 'layout/_nav';
@import "layout/_sections";

/* @import 'pages/_404'; */
@import 'pages/_tarieven'; 
@import 'pages/_huidproblemen';
@import 'pages/_blog';