details {
    &:not(.fake-select) {
         @apply py-15px border-b pl-[33px];

        summary {
            @apply cursor-pointer list-none relative text-base;

        &::marker {
             display: none;
             content: "";
         }
        &::-webkit-details-marker {
             display: none;
             content: "";
         }


        &::before {
             @apply transition-all duration-300 ease-out;
             content: '';
             display: inline-block;
             position: absolute;
             top: calc(50%-9px);
             width: 18px;
             height: 18px;
             margin-right: 10px;
             margin-left: -33px;
             background-position: center center;
             background-size: contain;
             background-repeat: no-repeat;
             background-image: url('../../assets/images/icons/icon_faq.svg');
         }

        &+p {
             @apply mt-2.5;
         }
        }

        &[open] {
            summary {
                @apply font-bold;

            &::before {
                 @apply rotate-45;
             }
            }
        }
     }


    &.fake-select {
        &[open] {
            * {
                @apply text-black bg-white;
            }
         }
     }
}