
.h1-mega {
    @apply text-m-h1 sm:text-h1-mega;
}
.h1 {
    @apply text-m-h1 sm:text-h1;
}
.h2 {
    @apply text-h2;
}
.h3 {
    @apply text-h3 font-bold;
}
.h4 {
    @apply text-h4 uppercase font-bold tracking-[1px];
}
.text-quote {
    @apply text-m-quote sm:text-d-quote italic;
}
.text-tag {
    @apply text-dm-tag uppercase font-bold tracking-[1px];
}
.text-big {
    @apply text-m-big sm:text-d-big;
}
.text-sm {
    @apply text-dm-sm;
}
p{
    @apply text-base;
}

.bg-dark-blue,
.bg-black {
    @apply text-white;
}