@layer components {
    ul {
        @apply mt-2.5 mb-20px;

        &.list-checks {
            li {
                @apply relative pl-30px;

                /* works with padding not margin as margin moves the ticks right but padding does not*/
                &::before {
                    @apply absolute block;
                    content: '';
                    vertical-align: middle;
                    width: 18px;
                    height: 14px;
                    left: 0px;
                    top: 5px;
                    background-position: center center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url('../../assets/images/icons/icon_checkmark.svg');
                }
            }
        }

        &.list-disc {
            li {
                @apply relative ml-[26px];
            }
        }
    }

    .content-container {
        ul {
            list-style-type: disc;
            list-style-position: inside;
        }
    }
}