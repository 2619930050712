.tarieven {
  @apply md:container;
  > div {
    @apply bg-orange bg-white p-5;

    > div {
      @apply mb-10 space-y-2.5;

      * {
        @apply text-base;
      }

      h3 {
        @apply text-orange font-bold;
      }

      h6 {
        @apply flex justify-between;
      }

      span {
        @apply text-black;
      }

      span.bold {
        @apply font-bold text-black;
      }
    }
  }
}
