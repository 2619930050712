@layer base {
    html {
        body {
            @apply bg-beige;
            main {
                section {
                    .swiper {
                        @apply pb-20 sm:pb-100px;
                    }

                    @apply pb-20 sm:pb-100px;
                }
            } 

            #CookiebotWidget {
                display: none;
            }
        }
    }
}

.container {
    @apply px-6 md:mx-auto w-full;
}