.locatie {
    @apply pb-100px bg-beige;
    &>div {
        div {
            &:first-child {

                p {
                    @apply flex justify-between text-black pb-15px text-base;

                    span {
                        @apply font-black;
                    }
                }
            }

            &:last-child {

                iframe {
                    @apply w-full h-full;
                }
            }
        }
    }
}