@layer components {
  /* GLOBAL CARD */
  .card {
    /* INSTANCE WHEN USED AS A CONTAINER */
    .card-image {
      img {
        @apply transition-all duration-300;
      }
    }

    /* INSTANCE WHEN USING BG IMAGE */
    .card-image {
      @apply w-full aspect-square transition-all duration-300 bg-center bg-no-repeat;
      background-size: 100% auto;
    }

    &:hover {
      .card-image {
        background-size: 105% auto;
      }

      .card-image {
        img {
          transform: scale(1.05);
        }
      }

      .voor-na {
        div {
          background-size: auto 105%;
        }
      }
    }

    h3 {
      /* @apply break-all; */
    }

    .card-text {
      h3 {
        @apply md:text-d-quote text-base font-bold;
      }

      p {
        @apply text-h4 md:text-base items-center;
      }

      blockquote {
        p {
          @apply pt-4 italic font-medium md:text-d-quote text-m-quote;

          &::before {
            content: "\201C";
          }

          &::after {
            content: "\201D";
          }
        }
      }
    }
  }

  .card-curve {
    /* GENERAL CURVED CARD */
    .card {
      .card-image {
        img {
          @apply object-cover w-full sm:h-[350px];
        }
      }

      .card-text {
        p {
          @apply text-tag;
        }
      }
    }

    /* HUIDTHERAPEUT */
    &.huidtherapeuten {
      @apply grid sm:first:rounded-tl-100px lg:grid-cols-3 grid-cols-2 gap-y-5 sm:gap-y-60px sm:gap-x-4 gap-x-2.5;

      .card {
        &:nth-child(4n + 1) .card-image {
          @apply rounded-tl-40px sm:rounded-tl-100px;
        }

        &:nth-child(2) .card-image {
          @apply rounded-tr-40px sm:rounded-tr-none;
        }

        &:nth-child(4n + 4) .card-image {
          @apply rounded-br-none sm:rounded-br-100px;
        }

        .card-image {
          @apply sm:col-span-12 col-span-12 aspect-auto overflow-hidden;
        }
      }
    }

    /* BLOG */
    &.blog {
      @apply grid sm:first:rounded-tl-100px grid-cols-1 gap-y-5 sm:grid-cols-2 sm:gap-y-60px sm:gap-x-4 lg:grid-cols-4;

      a {

        &:nth-child(4n + 1) .card-image {
          @apply rounded-tl-40px sm:rounded-tl-100px;
        }

        &:nth-child(4n + 4) .card-image {
          @apply rounded-br-40px sm:rounded-br-100px;
        }
        
        .card {
          @apply grid grid-cols-12 sm:block sm:gap-y-15px col-span-1;
  
          .card-image {
            @apply col-span-5 sm:col-span-12 aspect-square lg:aspect-22/25 overflow-hidden;
          }
  
          .card-text {
            @apply md:mt-15px col-span-7 sm:col-span-12 self-center col-start-7;
          }
        }

      }
    }
  }

  /* Product collection */
  .product-collection {
    .card {
      .card-image {
        @apply aspect-[308/450] bg-cover rounded-br-[25px] mb-[30px] bg-grayf6;
      }
      .card-text {
        @apply mb-20;
        p {
          &:first-child {
            @apply text-dm-sm;
            @media (min-width:768px) {
              @apply text-dm-sm;
            }
          }
          &:last-child {
            @apply text-h3 font-bold;
          }
        }
      }
    }
  }

  /* Huidtherapeuten */
  #people {
    #results {
      .card-curve {
        &.huidtherapeuten {
          .card-link {
            .card {
              .card-image {
                @media (min-width: 640px) {
                  border-top-left-radius: 0;
                }
              }
            }
            &:nth-child(3n+1) {
              .card {
                .card-image {
                  @media (min-width: 640px) {
                    border-top-left-radius: 6.25rem;   
                  }
                }
              }
            }
            &:nth-child(3n) {
              .card {
                .card-image {
                  @media (min-width: 640px) {
                    border-bottom-right-radius: 6.25rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
}