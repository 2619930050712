#filters {
  .reset-filters {
    @apply no-underline;
    &:hover {
      text-shadow: inherit;
    }
  }
  .checkbox {
    @apply relative;
    input {
      &[type='checkbox'] {
          @apply border-2 border-black;
      }
    }
    &.active {
      &::before {
        background-image: url('../../assets/images/icons/icon_checkmark_orange.svg');
        @apply content-[""] w-[14px] h-[14px] bg-contain bg-no-repeat bg-center absolute left-1/2 -translate-x-1/2 pointer-events-none;
      }
    }
  }
  details {
    &:first-child {
      @apply border-t 
    }
    &:not(.fake-select) {
      padding: 0;
      summary {
        @apply font-bold;
        &:before {
          @apply hidden;
        }
        &:after {
          background-image: url('../../assets/images/icons/icon_chevron.svg');
          @apply content-[''] w-[21px] h-[11px] bg-contain bg-no-repeat bg-center inline-block absolute top-[27px] right-0 transition duration-300 rotate-180;
        }
      }
      &[open] {
        summary {
          &:after {
            @apply rotate-0;
          }
        }
      }
    }
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
}

#results {
  .card-link {
    .card-image {
      @apply aspect-[308/450];
    }
    .card-text {
      @apply mb-20;
      p {
        &:first-child {
          @apply text-dm-sm;
          @media (min-width:768px) {
            @apply text-dm-sm;
          }
        }
        &:last-child {
          @apply text-h3 font-bold;
        }
      }
    }

  }
  .addtocart {
    input[type="image"] {
      &:hover {
        @apply border-transparent;
      }
    }
  }
}

#people {
  #results {
    .card-curve {
      &.huidtherapeuten {
        .card-link {
          .card-image {
            @apply aspect-auto;
          }
        }
      }
    }
  }
}