.rating-grid-container {
  @apply flex flex-col;

  & > div {
    h3 {
      @apply flex items-center text-black md:text-button text-base font-bold no-underline;
      padding-top: 15px;
      padding-bottom: 5px;
    }

    & > div {
      @apply flex flex-col;

      & > span {
        @apply text-h4 md:text-base grid grid-cols-4 items-center md:w-max;

        div {
          @apply flex flex-row md:space-x-1 space-x-0.5 md:ml-5 justify-end;

          span {
            @apply block border border-dark-blue w-3 h-3 rounded-full aspect-square;

            &.dot {
              @apply block bg-dark-blue w-3 h-3 rounded-full;
            }
          }
        }
      }
    }
    p {
      @apply py-15px;
      &.small {
        padding-top: 0;
        font-size: 0.9rem;
      }
    }
  }
}

.not-single {
  .rating-grid-container {
    @apply grid md:grid-cols-1;

    & > div {
      @apply col-start-1;

      & > div {
        & > span {
          @apply col-start-1 md:grid-cols-4 grid-cols-2;
        }
      }
    }
  }
}
