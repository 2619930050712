.h-modal{
    @apply bg-dark-blue bg-opacity-60;

    *:not(button):not(a) {
        @apply text-black;
    }
    
    .modal-body{
        max-width: 521px;
    }
}