  /* nav  */
  header{
    @apply bg-white;

    #top-bar {
        @apply py-[7px] bg-dark-blue hidden md:block;

        ul {
            @apply m-0 p-0;

            li {
                &:not(.cart-container){
                margin-right: 40px;
                }
                a {
                    @apply text-dm-sm font-normal text-white no-underline hover:text-orange hover:underline;
                }
            }
        }
    }

    nav {

        &>div {
            .logo {
                @apply w-full max-w-fit;
            }

            button {
                @apply md:text-button text-dm-sm md:px-6 px-4 py-2.5 block md:hidden w-min;
            }

            [data-id="mm-arrow"] {
                @apply transition-transform p-1;
                &.active {
                     @apply rotate-180;
                     filter: invert(54%) sepia(52%) saturate(2906%) hue-rotate(357deg) brightness(99%) contrast(88%);
                 }
            }
        }

        .main-menu {

            a {
                @apply md:text-button text-base;

                &:not(.bekijk):not(.btn){
                    @apply font-normal text-black no-underline hover:underline underline-offset-5 hover:decoration-orange hover:text-orange;
                }
            }


            /* Hide scrollbar for IE, Edge and Firefox */
            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */

            .mega-menu {
                /* ESCAPE CONTAINER */
                @apply left-2/4 right-2/4 w-screen absolute z-1;
                margin-left: -50vw;
                margin-right: -50vw;
                /* ESCAPE CONTAINER */

                top: 72px;

                @media only screen and (min-width: 991px) {
                    top: 78px;
                }

                .grid {

                    .flex {

                        a {
                            &:not(.bekijk) {
                                @apply pt-15px text-footer-links no-underline hover:underline hover:text-black hover:decoration-orange underline-offset-5;
                                text-shadow: none;
                            }
                        }
                    }

                    button {
                        &.bekijk {
                            /* BUTTON STYLES MANUALLY ADDED FROM STYLEGUIDE */
                            @apply bg-beige hover:bg-orange active:bg-orange;
                        }
                    }
                }

                .btn-close {
                    @apply w-6 h-6 block hover:cursor-pointer absolute top-10 right-10 md:right-20 bg-center bg-contain duration-300 ease-out;
                    background-image: url(/assets/images/icons/close-mega-menu-icon.png);
                }
            }
        }
    }
}