footer.bg-dark-blue {
    h5 {
        @apply font-bold;
    }

    a {
        @apply text-white no-underline hover:text-orange font-normal text-footer-links hover:underline;
    }

    ul {
        @apply xl:mb-0 mb-4;
    }

    button {
        @apply m-0;
    }

    .grid{
        ul{
            li{
                @apply xl:my-[2.5px] my-2.5;
            }
        }
    }
   
    .sm-icons{
        a{
            path{
                @apply fill-white;
            }
            &:hover{
                path{
                    @apply fill-orange;
                }
            }
          
        }
    }

    .quick-links {
        @apply text-h4 items-center flex xl:flex-row flex-wrap xl:order-2 order-3 mb-0 mt-[26px];

        li {
            @apply xl:mb-0 mb-6;

            &:not(.lang-selector) {
                a {
                    @apply xl:mr-4 mr-6 xl:mt-0 mt-6;
                }
            }
        }
    }

    .copyright {
        @apply flex justify-center py-5 text-center;

        p {
            @apply text-dm-sm leading-[22.5px] font-normal text-h4 xl:block flex flex-col justify-items-center text-[#C5CED5];
    
            a {
                @apply xl:ml-4 w-full font-medium text-white text-h4 hover:text-orange;
            }
        }
    }
}