/* SINGLE / GLOBAL */
.voor-na {
    @apply flex aspect-square transition-all duration-150 max-h-[308px];
}

/* GRID & SLIDE COMPONENT */
.not-single {
    .voor-na {
        @apply md:grid-cols-4 grid;

        div {
            @apply md:col-span-2 col-span-1 first:md:mr-0 first:md:col-start-1 last:md:ml-0;
        }
    }
}

/* UNIQUE TAB VARIATION */
.tab-component {
    .voor-na {
        @apply md:grid md:grid-cols-2 grid-cols-1 space-x-2 aspect-unset hover:cursor-pointer max-h-max;

        div {
            @apply flex col-span-1 flex-col justify-end text-right px-2.5 py-1.5 bg-cover bg-center aspect-square max-w-[308px] w-full ;
     
            span {
                @apply text-white text-dm-sm font-bold tracking-[1px];
            }
        }
    }
}