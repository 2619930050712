.swiper {
  &.has-fade {
    &:after {
      position: absolute;
      content: "";
      display: block;
      height: 100%;
      z-index: 100;
      top: 0;
      right: 0;
      width: calc(100% / 8);
      background: linear-gradient(
        to right,
        rgba(256, 256, 256, 0) 0%,
        rgba(254, 250, 246, 0.8) 70%
      );
      transform: scale(1.01);

      @media only screen and (min-width: 768px) {
        width: calc(100% / 6);
        background: linear-gradient(
          to right,
          rgba(256, 256, 256, 0) 0%,
          rgba(254, 250, 246, 1) 60%
        );
      }
    }
  }
}

.swiper {
  &.has-fade-small {
    &:after {
      pointer-events: none;
      position: absolute;
      content: "";
      display: block;
      height: 100%;
      z-index: 100;
      top: 0;
      right: 0;
      width: calc(100% / 15);
      background: linear-gradient(
        to right,
        rgba(256, 256, 256, 0) 0%,
        rgba(254, 250, 246, 0.8) 70%
      );
      transform: scale(1.01);

      @media only screen and (min-width: 768px) {
        width: calc(100% / 15);
        background: linear-gradient(
          to right,
          rgba(256, 256, 256, 0) 0%,
          rgba(254, 250, 246, 1) 60%
        );
      }
    }
  }
}
