/* https://tailwindcss.com/docs/functions-and-directives#directives */
@layer base {
	html {
		font-family: 'Bw Quinta Pro Regular', system-ui, sans-serif;
		@apply text-black scroll-smooth;
	}

	body,
	html {
		@apply max-w-full min-h-screen;
	}

	body {
		@apply relative;
	}
	::selection{
		@apply text-white bg-orange;
	}

}

.grecaptcha-badge {
	visibility: hidden !important;
}