@layer components {
  button:not(#nav-burger),
  a.btn {
    @apply bg-orange hover:bg-white text-white hover:text-orange font-bold py-15px px-6 rounded-tl-xl rounded-br-xl text-button border-button border-orange transition-all duration-300 ease-out hover:no-underline;
  }

  button:not(#nav-burger),
  a {
    &.btn-secondary {
      @apply bg-transparent hover:bg-orange text-orange hover:text-white py-2.5 hover:no-underline no-underline;
    }
  }

  button:not(#nav-burger),
  a {
    &.btn-secondary-smaller {
      height: 36px;
      @apply bg-transparent hover:bg-orange text-orange hover:text-white py-1 hover:no-underline no-underline;
    }
  }

  button:not(#nav-burger).search-button {
    @apply bg-transparent hover:bg-transparent border-none py-2 px-3 md:py-3;
  }

  button[class*=" icon"],
  button[class^="icon-"] {
    padding-left: 17px;

    &::before {
      @apply inline-block bg-center bg-contain bg-no-repeat align-middle;
      content: "";
      width: 18px;
      height: 18px;
      margin-right: 17px;
    }

    &.icon-phone {
      &::before {
        background-image: url("../../assets/images/icons/icon_telefoon_w.svg");
      }

      &:hover {
        &::before {
          background-image: url("../../assets/images/icons/icon_telefoon.svg");
        }
      }
    }
  }
}
