@font-face {
    font-family: 'Bw Quinta Pro Regular';
    src: url('../../../assets/fonts/BwQuintaPro-Regular.otf');
    src: url('../../../assets/fonts/BwQuintaPro-Regular.woff') format('woff'),
    url('../../../assets/fonts/BwQuintaPro-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bw Quinta Pro Regular';
    src: url('../../../assets/fonts/BwQuintaPro-RegularItalic.otf');
    src: url('../../../assets/fonts/BwQuintaPro-RegularItalic.woff') format('woff'),
    url('../../../assets/fonts/BwQuintaPro-RegularItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Bw Quinta Pro Regular';
    src: url('../../../assets/fonts/BwQuintaPro-Medium.otf');
    src: url('../../../assets/fonts/BwQuintaPro-Medium.woff') format('woff'),
    url('../../../assets/fonts/BwQuintaPro-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Bw Quinta Pro Regular';
    src: url('../../../assets/fonts/BwQuintaPro-MediumItalic.otf');
    src: url('../../../assets/fonts/BwQuintaPro-MediumItalic.woff') format('woff'),
    url('../../../assets/fonts/BwQuintaPro-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Bw Quinta Pro Regular';
    src: url('../../../assets/fonts/BwQuintaPro-Bold.otf');
    src: url('../../../assets/fonts/BwQuintaPro-Bold.woff') format('woff'),
    url('../../../assets/fonts/BwQuintaPro-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Bw Quinta Pro Regular';
    src: url('../../../assets/fonts/BwQuintaPro-BoldItalic.otf');
    src: url('../../../assets/fonts/BwQuintaPro-BoldItalic.woff') format('woff'),
    url('../../../assets/fonts/BwQuintaPro-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}