.mollie-form,
.mollie-plus-form {
  /* fixes issue arrow not showing dropdown */
  select {
    appearance: auto;
    border: 1px solid orange;
    margin-bottom: 10px;
    @media only screen and (min-width: 768px) {
      max-width: 400px;
    }
  }
}
